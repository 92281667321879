<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <validation-observer ref="leadRules">
      <b-form @submit.prevent>
        <b-row class="invoice-preview">
          <b-col cols="12">
            <b-card>
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Lead Details</th>
                    </tr>
                  </thead>
                </table>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="3">
                  <b-form-group label="Channel" label-for="h-channel">
                    <v-select v-model="leadData.channel" :taggable="true" :loading="dropdownLoading" @option:created="createNewDropdownValue" :value="leadData.channel" :options="leadOptions" :clearable="true"> </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group label="Reason*" label-for="h-reason">
                    <validation-provider name="Reason" #default="{ errors }" rules="required">
                      <v-select v-model="leadData.reason" :options="reasons" label="reason" :clearable="false"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="3" v-if="leadData.reason == 'VEHICLE ENQUIRY' || leadData.reason == 'TO LEASE A VEHICLE'">
                  <b-form-group label="Vehicles" label-for="h-vehicles">
                    <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'" :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" append-to-body :clearable="false"> </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="3" v-if="(leadData.reason == 'CAR HUNTING' || leadData.reason == 'TO SELL A VEHICLE') && leadData.id">
                  <b-form-group label="Vehicles" label-for="h-vehicles">
                    <v-select v-model="leadData.stockNumber" :options="vehicles" :disabled="leadData.status == 'E'" :value="leadData.stockNumber" :reduce="(val) => val.stockNumber" label="value" append-to-body :clearable="false"> </v-select>
                  </b-form-group>
                </b-col>

                <b-col v-if="$Can('lead_assign')" cols="3">
                  <b-form-group class="mr-1" label="Assigned User" label-for="h-user">
                    <validation-provider name="Assigned User" #default="{ errors }" rules="required">
                      <v-select v-model="leadData.userId" :taggable="true" :options="users" label="value" :value="leadData.userId" :reduce="(val) => val.userId" :clearable="false"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col v-if="!$Can('lead_assign')" v-show="false" cols="3">
                  <b-form-group class="mr-1" label="Assigned User" label-for="h-user">
                    <v-select v-model="leadData.userId" :taggable="true" :options="users" label="value" :value="leadData.userId" :reduce="(val) => val.userId" :clearable="false"> </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="leadData.status == 'E'">
                  <b-form-group label="Solution*" label-for="h-solution">
                    <validation-provider name="Solution" #default="{ errors }" :rules="leadData.id ? 'required' : ''">
                      <v-select v-model="leadData.solution" :taggable="true" append-to-body :value="leadData.solution" :options="solutions" label="solution" :clearable="true"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- 
            <b-col v-if="id != null" cols="12">
              <b-form-group label="Notes" label-for="h-notes">
                <b-form-textarea v-uppercase v-model="leadData.note" id="notes" placeholder="Notes" rows="4" />
              </b-form-group>
            </b-col> -->
              </b-row>

              <fieldset>
                <h6>Customer Details</h6>
                <b-row>
                  <!-- name -->
                  <b-col cols="6">
                    <b-form-group label="Name*" label-for="h-name">
                      <validation-provider name="Name" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.firstName" id="h-name" placeholder="Name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6">
                    <b-form-group label="Surname" label-for="h-surname">
                      <b-form-input v-uppercase v-model="leadData.lastName" id="h-surname" placeholder="Surname" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Driver's License Number" label-for="h-license">
                      <b-form-input v-uppercase v-model="leadData.driversLicense" id="h-name" placeholder="Driver's License" />
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Expiration Date" label-for="h-expiration">
                      <flat-pickr id="h-expiration" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="leadData.expiration" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Date of Birth" label-for="h-date">
                      <flat-pickr id="h-dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="leadData.dateOfBirth" />
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Social Security Number" label-for="h-ssn">
                      <b-form-input v-model="leadData.socialSecurity" id="h-ssn" placeholder="Social Security Number" />
                    </b-form-group>
                  </b-col>

                  <!-- email -->
                  <b-col cols="4">
                    <b-form-group label="Email" label-for="d-email">
                      <validation-provider name="Email" #default="{ errors }" rules="email">
                        <b-form-input v-uppercase v-model="leadData.email" id="d-email" placeholder="Email" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- phone -->
                  <b-col cols="4">
                    <b-form-group label="Phone" label-for="d-phone">
                      <b-form-input v-model="leadData.phone" id="d-phone" placeholder="Phone" />
                    </b-form-group>
                  </b-col>

                  <!-- Preferred Contact Method -->
                  <b-col cols="4">
                    <b-form-group label="Method*" label-for="h-method">
                      <validation-provider name="Method" #default="{ errors }" rules="required">
                        <v-select v-model="leadData.method" :options="methods" label="method" :clearable="false"> </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Message*" label-for="h-message">
                      <validation-provider name="Message" #default="{ errors }" rules="required">
                        <b-form-textarea v-uppercase v-model="leadData.message" id="message" placeholder="Message" rows="3" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <hr />
                <h6>Residence Information</h6>
                <b-row class="mt-1">
                  <!-- Car Details -->
                  <b-col cols="6">
                    <b-form-group label="Address" label-for="h-address">
                      <b-form-input v-uppercase v-model="leadData.address" id="h-address" placeholder="Address" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="City" label-for="h-city">
                      <b-form-input v-uppercase v-model="leadData.city" id="h-city" placeholder="City" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="State*" label-for="h-state">
                      <b-form-input v-uppercase v-model="leadData.state" id="h-state" placeholder="State" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Zip" label-for="h-zip">
                      <b-form-input v-uppercase v-model="leadData.zip" id="h-zip" placeholder="Zip" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Time of Address Years" label-for="h-years">
                      <b-form-input v-uppercase v-model="leadData.addressYear" id="h-years" placeholder="Time of Address Years" type="number" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Months" label-for="h-months">
                      <b-form-input v-uppercase v-model="leadData.addressMonth" id="h-months" placeholder="Months" type="number" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Residence Type" label-for="h-residenceType">
                      <b-form-input v-uppercase v-model="leadData.residenceType" id="h-residenceType" placeholder="Residence Type" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Monthly Payment" label-for="h-monthlyPayment">
                      <cleave id="listingPrice" v-model="leadData.monthlyPayment" class="form-control" :raw="true" :options="options.number" />
                    </b-form-group>
                  </b-col>
                  <!-- Car Details Finish -->
                </b-row>
              </fieldset>

              <fieldset v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <hr />
                <h6>Employment Information</h6>
                <b-row class="mt-1">
                  <!-- Car Details -->
                  <b-col cols="6">
                    <b-form-group label="Employer" label-for="h-employer">
                      <b-form-input v-uppercase v-model="leadData.employer" id="h-employer" placeholder="Employer" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Office Address" label-for="h-oAddress">
                      <b-form-input v-uppercase v-model="leadData.officeAddress" id="h-oAddress" placeholder="Office Address" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="City" label-for="h-officeCity">
                      <b-form-input v-uppercase v-model="leadData.officeCity" id="h-officeCity" placeholder="Office City" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="State" label-for="h-officeState">
                      <b-form-input v-uppercase v-model="leadData.officeState" id="h-officeState" placeholder="State" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Zip" label-for="h-officeZip">
                      <b-form-input v-uppercase v-model="leadData.officeZip" id="h-officeZip" placeholder="Zip" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Office Phone" label-for="h-officePhone">
                      <b-form-input v-uppercase v-model="leadData.officePhone" id="h-officePhone" placeholder="Office Phone" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Supervisor" label-for="h-supervisor">
                      <b-form-input v-uppercase v-model="leadData.supervisor" id="h-supervisor" placeholder="Supervisor" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Gross Monthly Income" label-for="h-grossIncome">
                      <cleave id="listingPrice" v-model="leadData.grossIncome" class="form-control" :raw="true" :options="options.number" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Other Monthly Income" label-for="h-otherIncome">
                      <cleave id="listingPrice" v-model="leadData.otherIncome" class="form-control" :raw="true" :options="options.number" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Occupation" label-for="h-occupation">
                      <b-form-input v-uppercase v-model="leadData.occupation" id="h-occupation" placeholder="Occupation" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Number of Years at Present" label-for="h-numberOfYears">
                      <b-form-input v-uppercase v-model="leadData.numberOfYears" id="h-numberOfYears" placeholder="Number of Years at Present" type="number" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Co-Applicant" label-for="h-coApplicant">
                      <b-form-checkbox v-model="leadData.coApplicant" class="mt-50" title="Co-Applicant" name="Co-Applicant" switch inline> Co-Applicant </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <hr />
                <h6>Trade-in Vehicle Information</h6>
                <b-row class="mt-1">
                  <!-- Car Details -->
                  <b-col cols="6">
                    <b-form-group label="Vehicle" label-for="h-tradeIn">
                      <b-form-input v-uppercase v-model="leadData.tradeIn" id="h-tradeIn" placeholder="Vehicle" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Vehicle Status" label-for="h-tradeInStatus">
                      <b-form-input v-uppercase v-model="leadData.tradeInStatus" id="h-tradeInStatus" placeholder="Vehicle Status" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                <hr />
                <h6>Down Payment</h6>
                <b-row class="mt-1">
                  <!-- Car Details -->
                  <b-col cols="6">
                    <b-form-group label="Down Payment" label-for="h-downPayment">
                      <cleave id="listingPrice" v-model="leadData.downPayment" class="form-control" :raw="true" :options="options.number" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>

              <fieldset v-if="leadData.reason == 'CAR HUNTING' || leadData.reason == 'TO SELL A VEHICLE'">
                <hr />
                <h6>Vehicle Details:</h6>
                <b-row class="mt-1">
                  <!-- Car Details -->
                  <b-col cols="4">
                    <b-form-group label="Make*" label-for="h-make">
                      <validation-provider name="Make" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.make" id="h-make" placeholder="Make" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Model*" label-for="h-model">
                      <validation-provider name="Model" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.model" id="h-model" placeholder="Model" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Year*" label-for="h-year">
                      <validation-provider name="Year" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.year" id="h-year" placeholder="Year" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Trim" label-for="h-trim">
                      <b-form-input v-uppercase v-model="leadData.trim" id="h-trim" placeholder="Trim" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Mileage & Unit*" label-for="h-mileageUnit">
                      <validation-provider name="Mileage & Unit" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.mileageUnit" id="h-mileageUnit" placeholder="Mileage & Unit" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Fuel Type*" label-for="h-fuelType">
                      <validation-provider name="Fuel Type" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.fuelType" id="h-fuelType" placeholder="Fuel Type" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="4">
                    <b-form-group label="Transmission*" label-for="h-transmission">
                      <validation-provider name="Transmission" #default="{ errors }" rules="required">
                        <b-form-input v-uppercase v-model="leadData.transmission" id="h-transmission" placeholder="Transmission" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Car Details Finish -->
                </b-row>
              </fieldset>

              <!-- Buttons -->
              <b-row>
                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button @click.prevent="formSubmitted" type="submit" variant="primary" class="mr-1 float-right">
                    Save
                  </b-button>

                  <b-button @click.prevent="complete" v-if="id != null && logged" type="submit" variant="success" class="mr-1 float-right">
                    Complete
                  </b-button>

                  <b-button @click.prevent="formSubmitted" v-if="id != null && logged" type="submit" variant="primary" class="mr-1 float-right">
                    Mark In Progress
                  </b-button>
                </b-col>
              </b-row>
              <!-- Buttons Finish -->
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import leadStoreModule from './leadStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Cleave,
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    router,
    store,
    leadStoreModule,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
    uppercase: {
      update(element) {
        element.value = element.value.toUpperCase();
      },
    },
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'lead';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, leadStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    var leadDataModel = {
      channel: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      method: null,
      message: null,
      quote: null,
      stockNumber: null,
      solution: null,
      reason: null,
      responseDate: null,
      responseMethod: null,
      note: null,
    };

    var localUser = JSON.parse(localStorage.getItem('userData'));

    return {
      dropdownLoading: true,
      logged: false,
      localUser,
      responseDate: null,
      responseMethod: null,
      note: null,
      interactionLogs: { leadId: null, responseMethod: null, responseDate: null, note: null },
      users: [],
      leadOptions: [],
      loading: true,
      leadData: leadDataModel,
      id: null,
      required,
      email,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER'],
      reasons: ['GENERAL ENQUIRY', 'VEHICLE ENQUIRY', 'CAR HUNTING', 'TO SELL A VEHICLE', 'TO LEASE A VEHICLE'],
      vehicles: [],
      solutions: ['SALE QUOTE CREATED', 'PURCHASE QUOTE CREATED', 'LEASE QUOTE CREATED', 'PROVIDED INFORMATION'],
      sales: [],
      purchases: [],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      if (this.leadOptions.find((x) => x.toUpperCase() == val.toUpperCase()) == undefined) {
        store.dispatch('lead/saveLeadChannel', { title: val.toUpperCase().trim() }).then((response) => {
          this.leadOptions.unshift(response.data);
          this.leadData.channel = response.data;
          this.dropdownLoading = false;
        });
      }
    },

    formSubmitted() {
      this.$refs.leadRules.validate().then((success) => {
        if (success) {
          this.loading = true;

          if (this.id != null) {
            if (this.leadData.status == 'A') {
              this.leadData.status == 'B';
            }

            store.dispatch('lead/updateLead', this.leadData).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Lead has been updated',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getByIdLead();
            });
          } else {
            this.leadData.status = 'B';
            store.dispatch('lead/saveLead', this.leadData).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Lead Insert Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              router.push({ name: 'leads-preview', params: { id: response.data.id } });
            });
          }
        }
      });
    },

    complete() {
      this.$refs.leadRules.validate().then((success) => {
        if (success && this.leadData.solution != null) {
          this.loading = true;
          this.leadData.status = 'D';
          store.dispatch('lead/updateLead', this.leadData).then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Lead has been completed',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            this.getByIdLead();
          });
        } else if (this.leadData.solution == null) {
          this.$swal({
            title: 'Please enter a solution!',
            text: 'Lead can not be completed without a solution.',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      });
    },

    interactionLog() {
      this.loading = true;
      this.interactionLogs.leadId = this.id;
      this.interactionLogs.status = this.leadData.status;

      store
        .dispatch('lead/interactionLog', this.interactionLogs)
        .then((response) => {
          this.interactionLogs = { leadId: null, responseDate: null, responseMethod: null, note: null };
          this.logged = true;
          this.getByIdLead();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Interaction log has been saved',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    createPurchase() {
      this.loading = true;
      if (this.leadData.get_inventory == null && this.leadData.stockNumber == null) {
        this.$swal({
          title: "There isn't a selected vehicle!",
          text: 'Please select a vehicle to create a quote.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else {
        store
          .dispatch('lead/leadPurchaseQuoteCreate', this.leadData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote has been created',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.getByIdLead();
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    createSale() {
      this.loading = true;
      if (this.leadData.get_inventory == null && this.leadData.stockNumber == null) {
        this.$swal({
          title: "There isn't a selected vehicle!",
          text: 'Please select a vehicle to create a quote.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        this.loading = false;
      } else {
        store
          .dispatch('lead/leadSaleQuoteCreate', this.leadData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote has been created',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.getByIdLead();
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    getByIdLead() {
      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;
        store
          .dispatch('lead/getLeadById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.leadData = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'leads-list' });
          });
      } else {
        this.loading = false;
      }
    },

    getInventoryDrops() {
      store
        .dispatch('lead/getInventoryDropDowns')
        .then((response) => {
          response.data.inventory.forEach((element) => {
            if (element.value != null) {
              this.vehicles.push({
                value: element.value,
                stockNumber: element.stockNumber,
              });
            }
          });
          this.leadOptions = response.data.channels;
          this.dropdownLoading = false;

          this.getUsers();
        })
        .catch((error) => {});
    },
    getPurchase() {
      store
        .dispatch('lead/getAllPurchase', [])
        .then((res) => {
          res.data.data.data.forEach((element) => {
            this.purchases.push(String(element.id) + ' - ' + element.title);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSale() {
      store
        .dispatch('lead/getAllSale', [])
        .then((res) => {
          res.data.data.data.forEach((element) => {
            this.sales.push(String(element.id) + ' - ' + element.title);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      store
        .dispatch('lead/fetchUsers', [])
        .then((res) => {
          res.data.forEach((element) => {
            if (element != null) {
              this.users.push({
                userId: Number(element.id),
                value: String(element.name + ' ' + element.surname),
              });
            }
          });
          this.users.push({
            userId: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();

          if (!this.$Can('lead_assign')) {
            this.leadData.userId = this.localUser.id;
          }

          this.getByIdLead();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    varicon(val) {
      if (val == 'SMS') {
        return 'MessageSquareIcon';
      } else if (val == 'PHONE') {
        return 'PhoneCallIcon';
      } else if (val == 'EMAIL') {
        return 'MailIcon';
      } else if (val == 'LETTER') {
        return 'FileTextIcon';
      } else {
        return 'InboxIcon';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'secondary';
      } else if (val == 'B') {
        return 'warning';
      } else if (val == 'C') {
        return 'primary';
      } else if (val == 'D') {
        return 'success';
      } else if (val == 'E') {
        return 'info';
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.leadRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },

  formatPrice(value, val) {
    if (value != null) {
      let val = (value / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },

  created() {
    this.getInventoryDrops();
    // this.getPurchase();
    // this.getSale();
  },
  watch: {
    'leadData.reason': {
      handler: function(id, before) {
        if (this.leadData.id == null) {
          this.leadData.stockNumber = null;
          this.leadData.solution = null;
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
